

















import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import BScroll from 'better-scroll';

@Component({
  name: 'ScrollList',
})
export default class ScrollList extends Vue {
   @Prop({
     default: () => true,
   }) hasLoadedAll?:boolean;

   @Prop({
     default: () => false,
   }) loadStatus?: boolean;

   isPullingDown = false;

   isPullingUp = false;

   scrollInstance = null as any;

   mounted () {
     const bs = new BScroll('.scroll-wrapper', {
       probeType: 1,
       pullUpLoad: true,
       click: true,
       pullDownRefresh: true,
     });
     this.scrollInstance = bs;
     bs.on('pullingDown', this.pullingDown);
     bs.on('pullingUp', this.pullingUp);
   }

   @Watch('loadStatus')
   onChange (newValue:boolean, preValue:boolean) {
     if (newValue === false && preValue === true) {
       this.scrollInstance.finishPullUp();
       this.scrollInstance.finishPullDown();
       this.scrollInstance.refresh();
     }
   }

   pullingUp () {
     this.isPullingUp = true;
     this.$emit('loadMore');
     const wait: number = 0;
     setTimeout(() => {
       this.scrollInstance.finishPullUp();
       this.scrollInstance.refresh();
     }, wait);
   }

   pullingDown () {
     this.isPullingUp = false;
     this.isPullingDown = true;
     const wait: number = 300;
     setTimeout(() => {
       this.isPullingDown = false;
     }, wait);
     this.$emit('refresh');
   }

   destroyed () {
     if (this.scrollInstance) {
       this.scrollInstance.off('pullingDown', this.pullingDown);
       this.scrollInstance = null as any;
     }
   }
}
