





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Avatar',
})
export default class Avatar extends Vue {
  @Prop() imgUrl!: string;

  @Prop({ default: '' }) defaultName!: string;
}
